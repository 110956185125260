import React, {useCallback, useEffect, useState} from 'react'
import debounce from "../../utils/debounce";
import {advancedSearch} from "../../services/search";
import {useNavigate, useSearchParams} from 'react-router-dom';
import PartyCard from "../../components-v2/EntryDialog/dialogs/AddTopic/PartyCard";
import PostWithContrast from "../../components-v2/Post/PostWithContrast";
import TopicCard from "../../components-v2/EntryDialog/dialogs/AddTopic/TopicCard";
import OrganisationCard from "../../components-v2/EntryDialog/dialogs/AddTopic/OrganisationCard";
//import Tabs from "../../components-v2/Tabs";
import Loading from "../../components-v2/Loading";
import AdvacedSearchTab from "../../components/AdvancedSearch";

type User = {
    id: string;
    name: string;
    roles?: any;
    imageUrl?: string;
    userLocations?: any;
    organizationUsers?: any;
    verified?: boolean;
    public?: boolean;
};

type Post = {
    id: string;
    title: string;
};

type Topic = {
    id:string;
    title:string;
    description:string;
    address:string;
    createdAt:string;
};

type Organization = {
    id: string;
    name: string;
    description: string;
    imageUrl: string;
    type:string;
    orgLink: string;
    createdAt?: Date;
    organizationUsers:any
};

type SearchResponse = {
    users: User[];
    posts: Post[];
    topics: Topic[];
    organizations: Organization[];
};

export default function AdvancedSearch() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<SearchResponse>({
        users: [],
        posts: [],
        topics: [],
        organizations: [],
    });
    const [loading, setLoading] = useState(false)
    const [pageSelect,setPageSelect] = useState('people');

    const debouncedOnChange = useCallback(
        debounce(async (value) => {
            if (!value) {
                // Clear data if the search input is empty
                setData({ users: [], posts: [], topics: [], organizations: [] });
                return;
            }

            try {
                setLoading(true);

                const data = await advancedSearch(value);
                setData(data);


            } finally {
                setLoading(false);
            }
        }, 300),
        []
    );



    const [defaultValue,setDefaultValue] = useState(searchParams.get('q'))
    useEffect(() => {
        setDefaultValue(searchParams.get('q'))
    }, [searchParams]);
    useEffect(() => {

        debouncedOnChange(defaultValue)
    },[])

    const advacedSearchTab = [
                                            {name:'People',value:'people'},
        {name:'Posts',value:'posts'},
                                            {name:'Topics',value:'topics'},
                                            {name:'Organizations',value:'organization'}];


    const navigate = useNavigate()
    return (
        <div>
            <div className={'bg-line mt-9 max-w-[460px] mx-auto rounded-b-lg '}>
                <p className={'font-bold pt-2 pl-3'}>Advanced Search</p>
                {/*Search bar*/}
                <div className={'mt-[2px]'}>
                    <div className="relative flex flex-wrap items-stretch pl-3 pr-3">
                        <input
                            type="text"
                            className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-lg border
                            border-solid border-neutral-300 bg-white bg-clip-padding px-3
                            py-[0.25rem] text-base font-normal leading-[1.6] text-black
                            outline-none transition duration-200 ease-in-out focus:z-[3]
                            focus:border-gray-500 focus:text-primary
                            focus:shadow-[inset_0_0_0_1px_rgb(128,128,128)] focus:outline-none dark:border-neutral-600
                            dark:text-black dark:placeholder:border-neutral-600 dark:focus:border-black"
                            placeholder="Search Name,Post,Topic,Organization"

                            defaultValue={defaultValue ?? ''}
                            onChange={(e) => {debouncedOnChange(e.target.value)}}
                            />

                    </div>
                </div>

                <div className="pt-3 pb-3">
                   <AdvacedSearchTab setPageSelect={setPageSelect} tabObject={advacedSearchTab} currentTab={pageSelect}/>
                </div>
            </div>

            <div>
                {/*posts*/}
                {pageSelect === 'posts' && <div className={'mt-2 mb-3'}>
                    {
                        !loading ? (data?.posts?.length > 0 ? (
                            data?.posts?.map((x) => (
                                <div
                                    className={'bg-white mx-auto pl-2 pr-2 mx-mobile:mx-3 rounded-lg mb-6'}
                                >
                                    <PostWithContrast
                                        post={x}
                                        key={x?.id} pageLoad={function (): void {
                                        throw new Error('Function not implemented.');
                                    }}
                                    />
                                </div>
                            ))
                        ) : (
                            <div
                                className={'mt-10 text-center text-[14px] text-border'}>No
                                Posts</div>
                        )) : <div className="flex justify-center mt-10">
                            <Loading/>
                        </div>
                    }

                </div>}

                {/*peoples*/}
                {pageSelect === 'people' && <div className={'mt-8 mb-3'}>
                    {
                        !loading ? (data?.users?.length > 0 ? (
                            data?.users?.map((x) => (
                                <div
                                    className={'bg-zinc-100 mt-3 max-w-[436px] pt-3 pb-5 pl-2 pr-3 rounded-lg items-center mx-auto mx-mobile:mx-3'}>
                                    <PartyCard
                                        key={x.id} // Always add a unique key when mapping elements
                                        name={x.name}
                                        roleName={x?.roles?.name}
                                        profileImageUrl={x?.imageUrl}
                                        location={x?.userLocations?.name}
                                        organization={x?.organizationUsers?.[0]?.name}
                                        onClickCard={() => navigate(`/user/${x?.id}`)} // Uncomment if needed
                                        isVerified={x?.verified}
                                        isPublic={x?.public}
                                    />
                                </div>
                            ))
                        ) : (
                            <div
                                className={'mt-10 text-center text-[14px] text-border'}>No
                                People</div>
                        )) : <div className="flex justify-center mt-10">
                            <Loading/>
                        </div>
                    }

                </div>}

                {/*topics*/}
                {pageSelect === 'topics' && <div className={'mt-8 '}>
                    {
                        !loading ? (data?.topics?.length > 0 ? (
                            data?.topics?.map((x) => (
                                <div className={'mb-3 mx-auto mx-mobile:mx-3'}>
                                    <TopicCard title={x?.title} description={x?.description} id={x?.id}/>
                                </div>
                            ))
                        ) : (
                            <div
                                className={'mt-10 text-center text-[14px] text-border'}>No
                                Topics</div>
                        )) : <div className="flex justify-center mt-10">
                            <Loading/>
                        </div>
                    }

                </div>}

                {/*topics*/}
                {pageSelect === 'organization' && <div className={'mt-8'}>
                    {
                        !loading ? (data?.organizations?.length > 0 ? (
                            data?.organizations?.map((x) => (
                                <div className={'mb-3 mx-auto mx-mobile:mx-3'}>
                                    <OrganisationCard //createdAt={x?.createdAt}
                                        orgLink={x?.orgLink}
                                        description={x?.description}
                                        id={x?.id}
                                        name={x?.name}
                                        imageUrl={x?.imageUrl}
                                        type={x?.type}
                                        organizationUsers={x?.organizationUsers}
                                    />

                                </div>
                            ))
                        ) : (
                            <div
                                className={'mt-10 text-center text-[14px] text-border'}>No
                                Organizations</div>
                        )) : <div className="flex justify-center mt-10">
                            <Loading/>
                        </div>
                    }

                </div>}
            </div>
        </div>
    )
}