import React from 'react'
import linkeBlack from '../../../../assets/icons/link-black.svg'
import man from "../../../../assets/icons/man-black.svg";
import {useNavigate} from "react-router-dom";

type Props = {
    id?: string
    name?: string
    description?: string
    imageUrl?: string
    type?: string
    orgLink?: string
    createdAt?: string
    organizationUsers?:any
}

export default function OrganisationCard({id,name,description,imageUrl,type,orgLink,organizationUsers}: Props) {
    const navigate = useNavigate()

    return (
        <div className={'bg-zinc-100 mt-2 max-w-[436px] mx-auto pt-3 pb-5 pl-2 pr-2 rounded-lg cursor-pointer'}  onClick={() => navigate(`/organization/${id}`)}>
            <div className={'flex flex-row justify-between pr-1'}>
                <p className="text-sm font-semibold leading-6 ">
                    {name}
                </p>
                <img
                    onClick={(event) => {
                        event.stopPropagation()
                    }}
                    src={imageUrl}
                    className="h-[40px] w-[40px] rounded-full object-cover border"
                />
            </div>
            <p className="text-[14px] ">{type}</p>
            <p className="mt-2 mb-3 text-[14px] font-normal leading-4  whitespace-pre-line">
                {description}
            </p>
            {orgLink && <div className={'mt-5 flex'}>
                <img
                    onClick={(event) => {
                        event.stopPropagation()
                    }}
                    src={linkeBlack}
                    className="h-[16px] w-[16px] object-cover"
                />
                <span
                    className="ml-2 text-[14px] "
                    style={{fontFamily: 'Inter'}}
                >
              {orgLink}
            </span>
            </div>}

            {<div className={'mt-2 flex'}>
                <img
                    onClick={(event) => {
                        event.stopPropagation()
                    }}
                    src={man}
                    className="h-[16px] w-[16px] object-cover"
                />
                <span className="ml-2 text-[14px] ">
              Followers : {organizationUsers?.length > 0 ? organizationUsers?.length : 0}
            </span>
            </div>}

        </div>
    )
}
