import React from 'react'
import TopicFollowButton from "../../../TopicFollowButton";
import {useNavigate} from "react-router-dom";

type Props = {
    id?: string
    title?: string
    description?: string
    address?: string
    createdAt?: string
}

export default function TopicCard({id,title,description,address,createdAt}: Props) {
    const navigate = useNavigate()

    return (
        <div
            className={'bg-zinc-100  mt-2 max-w-[436px] mx-auto pt-5 pb-5 pl-2 pr-2 rounded-lg cursor-pointer'}
            onClick={() => {navigate(`/topic/${id}`)}}
        >
            <div className={'flex flex-row justify-between pr-1'}>
                <p className="text-[16px] font-semibold">{title}</p>
                {id && id !== 'local' && (
                    <TopicFollowButton topicId={id} />
                )}
            </div>
            <p className="mt-[12px] text-[14px] leading-[21px]">{description}</p>

        </div>
    )
}
