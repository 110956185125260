import React from 'react';
import {cn} from "../../utils/cn";

type Props = {
    setPageSelect: (ct: string) => void;
    tabObject?: { name: string; value: string }[]; // Adjusted the type for clarity
    currentTab: string;
};

export default function AdvacedSearchTab({ setPageSelect, tabObject,currentTab }: Props) {
    return (
        <div className="flex flex-row justify-evenly ">
            {tabObject?.map((tab) => (
                <div
                    key={tab.value} // Add a unique key for each tab
                    className={cn('cursor-pointer text-[10px] w-[10px] font-semibold ' +
                        'h-[19px] flex justify-center items-center min-w-[80px] ' +
                        'border-[2px] border-black rounded-full hover:bg-line ' +
                        'transition-all leading-[7px]',{'bg-zinc-100':currentTab === tab.value})}
                    onClick={() => setPageSelect(tab.value)}
                >
                    {tab.name}
                </div>
            ))}
        </div>
    );
}
