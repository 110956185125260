import React, { useEffect, useState } from 'react'
import { getHowLongAgo } from './helpers'
import VerifiedIconSVG from '../../assets/icons/verified-blue.svg'
import PublicIconSVG from '../../assets/icons/public-yellow.svg'
import { useNavigate } from 'react-router-dom'
import { useIdsStore } from './post.store'
import { getUserFollowerIds } from '../../services/helpersApis'
import { toast } from 'sonner'
// import { useUserStore } from './post.store'

type Props = {
  userName: string
  userRole?: string
  userProfileImg?: string
  customUserName?: string
  createdAt?: string
  isVerified?: boolean
  isPublic?: boolean
  isFollowed?: boolean
  isUsersQuotes?: boolean
  onClickFollowUser?: (userId: any) => void
  quotedUserId?: string
  onNoAuthCallback?: () => void
  // userFollowsIds?: any
}

export default function QuoteProfileComponent({
  userName,
  userProfileImg,
  userRole,
  customUserName,
  createdAt,
  isVerified,
  isPublic,
  isFollowed,
  isUsersQuotes,
  onClickFollowUser,
  quotedUserId,
  onNoAuthCallback,
  // userFollowsIds,
}: Props) {
  const followUser = () => {
    if (onNoAuthCallback) {
      onNoAuthCallback()
      return
    }
    if (quotedUserId) {
      if (onClickFollowUser) {
        onClickFollowUser(quotedUserId)
      }
    } else {
      toast.error('This houseguest was manually created for one-time use. It does not have a user profile and cannot be followed.')
    }
  }

  // Effect to update `isFollow` whenever `userFollowUserIds` or `authorId` changes
  const userFollowsIds = useIdsStore((state: any) => state.userFollowIds)

  const navigate = useNavigate()
  return (
    <div className="flex justify-between w-full items-center">
      <div className="">
        <h3 className="text-[14px] font-semibold flex items-center gap-1 cursor-pointer">
          <span
            className="overflow-hidden whitespace-nowrap text-ellipsis"
            onClick={(e) => {
              e.stopPropagation()
              if (onNoAuthCallback) {
                onNoAuthCallback()
                return
              }

              if (quotedUserId) {
                navigate(`/user/${quotedUserId}`)
              } else {
                toast.error('This houseguest was manually created for one-time use. It does not have a user profile and cannot be followed.')
              }
            }}
          >
            {customUserName || userName} ({getHowLongAgo(createdAt || '')})
          </span>

          <div className="flex gap-[1px] mb-[1px] items-center">
            {isVerified && (
              <img
                src={VerifiedIconSVG}
                className="w-[15px] h-[15px] "
                title="Verified"
              />
            )}

            {isPublic && (
              <img
                src={PublicIconSVG}
                className="w-[15px] h-[15px] "
                title="Public"
              />
            )}
            {!isUsersQuotes &&
              quotedUserId &&
              //quotedUserId &&
              (userFollowsIds.includes(quotedUserId) ? (
                <span
                  className={
                    'ml-2 text-gray-400 cursor-pointer overflow-hidden whitespace-nowrap text-ellipsis'
                  }
                  onClick={(event: any) => {
                    event.stopPropagation()
                    followUser()
                  }}
                >
                  ✓ Following
                </span>
              ) : (
                <span
                  className={'ml-2 text-blue-700 cursor-pointer'}
                  onClick={(event: any) => {
                    event.stopPropagation()
                    followUser()
                  }}
                >
                  + Follow
                </span>
              ))}
          </div>
        </h3>
        <h4 className="flex gap-1 -mt-[1px]">
          {userRole && (
            <span className="text-[14px] text-subtext leading-[15px]">
              {userRole || 'Houseguest'}
            </span>
          )}
        </h4>
      </div>

      <div className="flex items-center cursor-pointer">
        {userProfileImg && (
          <img
            src={
              userProfileImg ||
              'https://static.vecteezy.com/system/resources/previews/020/765/399/non_2x/default-profile-account-unknown-icon-black-silhouette-free-vector.jpg'
            }
            className="w-[40px] h-[40px] object-cover rounded-full mr-[12px]"
          />
        )}
      </div>
    </div>
  )
}
